import './index.scss';
import React, { Component } from "react";
import ReactGA from 'react-ga';

import TypeWriter from '../components/TypeWriter';

export default class Home extends Component {
  static noCompamyTag = 'None';

  constructor(props){
    super(props)

    this.state = {
      companyName: this.getHash()
    }

    this.renderTitle = this.renderTitle.bind(this);
    this.outboundLink = this.outboundLink.bind(this);
    
  }

  componentDidMount(){
    ReactGA.initialize('UA-133421172-1');

    let {companyName} = this.state;
    companyName = companyName || Home.noCompamyTag;
    ReactGA.set({ userId: companyName, dimension2: companyName });
    ReactGA.event({
      category: 'View',
      action: 'AtRndm',
      label: companyName 
    });
  }

  outboundLink(link='', e){
    let {companyName} = this.state;
    companyName = companyName || Home.noCompamyTag;
    ReactGA.event({
      category: 'Outbound Link',
      action: link,
      label: companyName
    });
  }
 
  getHash(){
    if(typeof window !== 'undefined') {
      return decodeURI(window.location.hash.substr(1));
    }
  }

  renderTitle(){
    const {companyName} = this.state;
    const speed = companyName ? 100 : 100;
    const text = `Full Stack Developer ${companyName ? `@ ${companyName} (?)` : ''}`;
    return <TypeWriter speed={speed} text={text} />
  }

  render(){
    return (
      <div className="home">
        <div className="wrap">
          <link href="https://fonts.googleapis.com/css?family=Source+Code+Pro" rel="stylesheet"></link>
          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"></link>
          <div className="main">{'<AtRndm />'}</div>
          <div className="content">
            <div className="title">{this.renderTitle()}</div>

            <div className="space"></div>
            Projects:
            <ul className="projects">
              <li>
                <i className="fas fa-link"></i> 
                <a onClick={this.outboundLink.bind(this, 'Face2Gene')} href="/fdna" target="_blank">Face2Gene</a>
              </li>
              <li>
                <i className="fas fa-link"></i> 
                <a onClick={this.outboundLink.bind(this, 'Stocks Screener')} href="https://trade.atrndm.com" target="_blank">Stock screener</a>
              </li>
            </ul>
            Social:
            <ul className="links">
              <li>
                <i className="fab fa-linkedin-in"></i> 
                <a onClick={this.outboundLink.bind(this, 'Linkedin')} href="https://www.linkedin.com/in/liron-eldar" target="_blank">Linkdin</a>
              </li>
              <li>
                <i className="fab fa-github"></i> 
                <a onClick={this.outboundLink.bind(this, 'Github')} href="https://github.com/lironeld" target="_blank">Github</a>
              </li>
            </ul>
          </div>
        </div>
    </div>
    )
  }
}
