import React, {Component} from 'react';

export default class TypeWriter extends Component {
    static defaultProps = {
        text: '',
        speed: 200
    }

    constructor(props){
        super(props)
        
        this.state = {
            text: ''
        }

        this.typeWrite = this.typeWrite.bind(this);

        const {text, speed} = this.props;
        this.typeWrite(text, speed);

    }

    typeWrite(text, speed){
        if(!text) return;
        for(let i=0; i<=text.length; i++){
            setTimeout(() => {
                this.setState({text: this.state.text + text.charAt(i)})
            }, speed * i);
        }
    }

    render(){
        return <span>{this.state.text}</span>
    }
}